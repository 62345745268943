'use client';
import Image from 'next/image';
import { FC } from 'react';
import congstarLogo from '../../../images/congstar-wort_bild_marke_v_1-negativ-srgb.png';
import { CustomButton } from '../Buttons';
import { routes } from '@/const/routes';
import Link from 'next/link';

export const AuthenticatedNavbar: FC = () => {
  return (
    <nav className="overflow-x-scroll shadow md:overflow-visible">
      <div className="flex mx-auto">
        <div className="flex h-16">
          <div className="flex items-center">
            <Link href={routes.disclaimer}>
              <CustomButton
                additionalClassNames="ml-4 mt-2"
                type="primaryOutlined">
                Zurück zur Startseite
              </CustomButton>
            </Link>
          </div>
        </div>
        <Image
          className="ml-auto"
          style={{ width: '130px' }}
          src={congstarLogo}
          alt="congstar logo"
        />
      </div>
    </nav >
  );
};
