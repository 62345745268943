import React, { ReactNode } from 'react';

type ButtonsProps = {
  onClickFn?: () => void;
  onFocusFn?: () => void;
  type:
    | 'congstarButton'
    | 'primaryOutlined'
    | 'primaryFilled'
    | 'secondaryOutlined'
    | 'secondaryFilled'
    | 'greyOutlined'
    | 'warningOutlined';
  additionalClassNames?: string;
  disabled?: boolean;
  tabIndex?: number;
  children: ReactNode;
  form?: string;
};

const baseStyles =
  'relative inline-flex items-center justify-center rounded-md border px-4 py-2 shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50';

const buttonTypeStylesMap = {
  congstarButton:
    'disabled:bg-congstar-blue border-congstar-blue bg-congstar-blue text-congstar-black hover:border-congstar-blue hover:bg-congstar-white hover:text-congstar-black focus:ring-congstar-blue',
  primaryOutlined:
    'border-interseroh-primary text-interseroh-primary hover:border-interseroh-secondary hover:text-interseroh-secondary focus:ring-interseroh-secondary',
  primaryFilled:
    '"border-interseroh-primary bg-interseroh-primary text-white hover:border-interseroh-secondary hover:bg-interseroh-secondary focus:ring-interseroh-secondary',
  secondaryOutlined:
    'border-interseroh-secondary text-interseroh-secondary hover:border-interseroh-primary hover:text-interseroh-primary focus:ring-interseroh-primary',
  secondaryFilled:
    '"border-interseroh-secondary bg-interseroh-secondary text-white hover:border-interseroh-primary hover:bg-interseroh-primary focus:ring-interseroh-primary',
  warningOutlined:
    'border-yellow-500 text-yellow-500 hover:border-yellow-600 hover:text-yellow-600 focus:ring-interseroh-secondary',
  greyOutlined: 'border-gray-300 bg-white text-gray-700 hover:bg-gray-50',
};

export const CustomButton = React.forwardRef<HTMLButtonElement, ButtonsProps>(
  (props, ref) => {
    const {
      onClickFn,
      onFocusFn,
      type,
      additionalClassNames = '',
      disabled = false,
      tabIndex,
      children,
      form,
    } = props;

    return (
      <button
        onFocus={onFocusFn}
        form={form}
        ref={ref}
        tabIndex={tabIndex}
        type={onClickFn ? 'button' : 'submit'}
        disabled={disabled}
        onClick={onClickFn}
        className={`${baseStyles} ${buttonTypeStylesMap[type]} ${additionalClassNames}`}>
        {children}
      </button>
    );
  }
);

CustomButton.displayName = 'CustomButton';
