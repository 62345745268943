import Image from 'next/image';
import { FC } from 'react';
import congstarLogo from '../../../images/congstar-wort_bild_marke_v_1-negativ-srgb.png';

export const UnauthenticatedNavbar: FC = () => {
  return (
    <nav className="overflow-hidden">
      <div className="mx-auto">
        <Image
          className="ml-auto"
          style={{ width: '130px' }}
          src={congstarLogo}
          alt="congstar logo"
        />
      </div>
    </nav>
  );
};
