'use client';
import { FC } from 'react';
import { AuthenticatedNavbar } from './AuthenticatedNavbar';
import { UnauthenticatedNavbar } from './UnauthenticatedNavbar';
import { usePathname } from 'next/navigation';
import { routes } from '@/const/routes';

export const Navbar: FC = () => {
  const pathname = usePathname();
  return (
    <>
      {pathname === routes.createLabel ? (
        <AuthenticatedNavbar />
      ) : (
        <UnauthenticatedNavbar />
      )}
    </>
  );
};

